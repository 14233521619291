import { io, Socket } from "socket.io-client";

let socket: Socket | null = null;

export const connectSocket = (userId: string) => {
  if (!socket) {
    socket = io({
      auth: {
        userId,
      },
      reconnection: true,
      withCredentials: true,
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from the Socket.IO server");
    });
  }

  return socket;
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

export const getSocket = () => {
  if (!socket) {
    throw new Error(
      "Socket connection has not been established yet. Please call connectSocket first."
    );
  }
  return socket;
};
