import React from "react";
import { Button } from "react-bootstrap";
import { useAuth } from "../Contexts/AuthContext"; // Adjust the import path as necessary
import { useNavigate, useLocation } from "react-router-dom";
import { FaDesktop, FaFileAlt, FaUsersCog, FaSignOutAlt } from "react-icons/fa";
import "./BottomNavbar.css"; // Import the CSS file for styling

const BottomNavBar = () => {
  const { logout, user } = useAuth(); // Get the logout function from the auth context
  const navigate = useNavigate(); // Get the navigate function from react-router-dom
  const location = useLocation(); // Get the current location

  const handleLogout = () => {
    logout(); // Call the logout function from the auth context
    navigate("/"); // Redirect to the login page or home page after logging out
  };

  // Function to check if the current path matches the button's path
  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="bottom-nav-bar">
      <Button
        variant={isActive("/dashboard") ? "primary" : "outline-primary"}
        className="nav-button"
        onClick={() => navigate("/dashboard")}
      >
        <FaDesktop className="me-2" />
        Dashboard
      </Button>
      <Button
        variant={isActive("/checklogs") ? "primary" : "outline-primary"}
        className="nav-button"
        onClick={() => navigate("/checklogs")}
      >
        <FaFileAlt className="me-2" />
        Logs
      </Button>
      {user?.role === "admin" && (
        <Button
          variant={isActive("/admin") ? "primary" : "outline-primary"}
          className="nav-button"
          onClick={() => navigate("/admin")}
        >
          <FaUsersCog className="me-2" />
          Admin
        </Button>
      )}
      <Button
        variant="outline-danger"
        className="nav-button"
        onClick={handleLogout}
      >
        <FaSignOutAlt className="me-2" />
        Logout
      </Button>
    </div>
  );
};

export default BottomNavBar;
