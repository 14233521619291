import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FaUserShield, FaUser } from "react-icons/fa"; // Importing icons
import "./UserCard.css"; // Import your custom CSS

interface UserCardProps {
  id: string;
  fullName: string;
  username: string;
  phoneNumber: string;
  role: string;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const UserCard: React.FC<UserCardProps> = ({
  id,
  fullName,
  username,
  phoneNumber,
  role,
  onEdit,
  onDelete,
}) => {
  const roleIcon = role === "admin" ? <FaUserShield /> : <FaUser />; // Choose icon based on role

  return (
    <Card className="user-card">
      <div className="user-card-header">
        {fullName}
        <span className="role-icon">{roleIcon}</span>{" "}
        {/* Icon displayed here */}
      </div>
      <Card.Body className="user-card-body">
        <Card.Text>
          <strong>Username:</strong> {username}
        </Card.Text>
        <Card.Text>
          <strong>Phone:</strong> {phoneNumber}
        </Card.Text>
        <div className="button-group"></div>
      </Card.Body>
    </Card>
  );
};

export default UserCard;
