import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaTimes } from "react-icons/fa";
import {
  BiWifi,
  BiWifi2,
  BiWifi1,
  BiWifiOff,
  BiSolidBatteryCharging,
} from "react-icons/bi";
import { ImSpinner2 } from "react-icons/im"; // Import the spinner icon
import "./ClaimedSystem.css";

interface ClaimedSystem {
  id: number;
  system_name: string;
  patient_name: string;
  status: string;
  battery_lvl: number;
  charge_status: boolean;
  signalStrength: number;
  screenshot?: any;
  claimedby: string | null;
  note: string | null;
  last_checked: number | null;
  moveSystemToDashboard: (id: number) => void;
  unclaimSystem: (id: number) => void;
  runCheck: (id: number) => Promise<void>;
}

const ClaimedSystem: React.FC<ClaimedSystem> = ({
  id,
  system_name,
  patient_name,
  status,
  battery_lvl,
  charge_status,
  signalStrength,
  unclaimSystem,
  runCheck,
  note,
  last_checked, // Use last_checked prop
  moveSystemToDashboard,
}) => {
  const [isRunningCheck, setIsRunningCheck] = useState(false);

  const handleRunCheck = () => {
    setIsRunningCheck(true);
    setTimeout(() => {
      setIsRunningCheck(false);
    }, 3000);
  };

  const getProgressBarVariant = () => {
    if (battery_lvl > 75) return "success";
    if (battery_lvl > 50) return "warning";
    if (battery_lvl > 25) return "danger";
    return "danger";
  };

  const getBatteryColor = (): string => {
    if (battery_lvl > 75) return "#28a745"; // Green for 75-100%
    if (battery_lvl > 50) return "#ffc107"; // Yellow for 51-75%
    if (battery_lvl > 25) return "#fd7e14"; // Orange for 26-50%
    return "#dc3545"; // Red for 0-25%
  };

  const getWifiIcon = () => {
    if (signalStrength > 75) return <BiWifi className="text-success" />;
    if (signalStrength > 50) return <BiWifi2 className="text-warning" />;
    if (signalStrength > 25) return <BiWifi1 className="text-danger" />;
    return <BiWifiOff className="text-danger" />;
  };

  const renderTooltip = (props: any) => (
    <Tooltip id="battery-tooltip" {...props}>
      {battery_lvl}% {charge_status && "(Charging)"}
    </Tooltip>
  );
  const formatTimestamp = (timestamp: number | null) => {
    if (!timestamp) return "Never checked";

    // Convert string to number if necessary
    const numericTimestamp =
      typeof timestamp === "string" ? Number(timestamp) : timestamp;

    // Validate the numeric timestamp
    if (isNaN(numericTimestamp)) return "Invalid timestamp";

    // Convert to Date and format
    const date = new Date(numericTimestamp);
    if (isNaN(date.getTime())) return "Invalid timestamp";

    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);
  };

  const isInvalidHeadbox = status === "invalidHeadbox";

  return (
    <Card
      bg={isInvalidHeadbox ? "warning" : status === "Offline" ? "danger" : ""}
      className="mb-3"
    >
      <Card.Header
        as="h5"
        className="d-flex justify-content-between align-items-center"
      >
        <div className="d-flex align-items-center">
          {system_name}
          <div className="ms-2">{getWifiIcon()}</div>
        </div>
        <Button
          variant="light"
          size="sm"
          onClick={() => unclaimSystem(id)}
          style={{ padding: "0.25rem 0.5rem" }}
        >
          <FaTimes />
        </Button>
      </Card.Header>

      <Card.Body>
        {/* Patient Name and Battery Icon */}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Card.Title className="mb-0">{patient_name}</Card.Title>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-${id}`}>{battery_lvl}%</Tooltip>}
          >
            <div className="battery-icon-container">
              <div className="battery-icon">
                {/* Battery Level */}
                <div
                  className="battery-level"
                  style={{
                    width: `${battery_lvl}%`,
                    backgroundColor: getBatteryColor(),
                  }}
                >
                  {/* Lightning Bolt for Charging */}
                  {charge_status && (
                    <div className="battery-charging-icon">⚡</div>
                  )}
                </div>
              </div>
              {/* Battery Tip */}
              <div className="battery-tip"></div>
            </div>
          </OverlayTrigger>
        </div>

        <Button
          variant={
            isInvalidHeadbox
              ? "secondary"
              : status === "Offline"
              ? "secondary"
              : "primary"
          }
          onClick={() => runCheck(id)}
          disabled={
            isInvalidHeadbox ||
            status === "Offline" ||
            status === "RUN_CHECK" ||
            status === "logging_in" ||
            status === "taking_ss" ||
            status === "awaiting_approval"
          }
        >
          {isInvalidHeadbox ? (
            "Invalid Headbox"
          ) : status === "Offline" ? (
            "Offline"
          ) : status === "RUN_CHECK" ? (
            <>
              <ImSpinner2 className="spinner-icon" /> Running...
            </>
          ) : status === "logging_in" ? (
            <>
              <ImSpinner2 className="spinner-icon" /> Logging In...
            </>
          ) : status === "taking_ss" ? (
            <>
              <ImSpinner2 className="spinner-icon" /> Taking Screenshot...
            </>
          ) : status === "awaiting_approval" ? (
            "Awaiting Approval"
          ) : (
            "Run Check"
          )}
        </Button>

        {/* Minimal styling for notes and last checked */}
        <div className="claimed-system-meta">
          <div className="claimed-system-meta-item2">
            <small>Last Checked: {formatTimestamp(last_checked)}</small>
          </div>
          <div className="claimed-system-meta-item">
            <small>Note: {note || "No notes"}</small>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ClaimedSystem;
