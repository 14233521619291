import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination"; // Bootstrap pagination component
import Spinner from "react-bootstrap/Spinner";

interface CheckLogEntry {
  id: number;
  timestamp: number;
  patientname: string;
  note: string | null;
  username: string;
}

const CheckLog: React.FC = () => {
  const [checkLogs, setCheckLogs] = useState<CheckLogEntry[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    fetchCheckLogs(currentPage);
  }, [currentPage]);

  const fetchCheckLogs = async (page: number) => {
    setLoading(true);
    try {
      const response = await fetch(`/api/v1/checklogs?page=${page}`, {
        method: "GET",
        credentials: "include", // Include cookies in the request
      });
      if (!response.ok) {
        throw new Error("Failed to fetch check logs");
      }
      const data = await response.json();
      setCheckLogs(data.logs);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const formatTimestamp = (timestamp: number): string => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);
  };

  return (
    <div className="container mt-4">
      <h1>Check Logs</h1>
      {loading ? (
        <div className="text-center my-4">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>PatientName</th>
                <th>Note</th>
                <th>User</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {checkLogs.map((log) => (
                <tr key={log.id}>
                  <td>{formatTimestamp(log.timestamp)}</td>
                  <td>{log.patientname}</td>
                  <td>{log.note || "No notes"}</td>
                  <td>{log.username}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Pagination */}
          <Pagination className="justify-content-center mt-4">
            {Array.from({ length: totalPages }, (_, i) => (
              <Pagination.Item
                key={i + 1}
                active={i + 1 === currentPage}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </>
      )}
    </div>
  );
};

export default CheckLog;
