import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext"; // Ensure the user is authenticated
import { useSocket } from "./SocketContext"; // Get the socket instance from SocketContext
import { toast } from "react-toastify"; // Import toast from react-toastify

interface System {
  id: number;
  system_name: string;
  patient_name: string;
  status: string;
  battery_lvl: number;
  charge_status: boolean;
  screenshot?: any;
  claimedby: string | null;
  last_checked: number | null;
  note: string | null;
}

interface SystemsContextType {
  systems: System[];
  setSystems: React.Dispatch<React.SetStateAction<System[]>>;
}

const SystemsContext = createContext<SystemsContextType | undefined>(undefined);

// Create the provider component
export const SystemsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [systems, setSystems] = useState<System[]>([]); // Global state for systems
  const auth = useAuth(); // Get auth context to check authentication
  const { socket } = useSocket(); // Get socket instance from context
  useEffect(() => {
    if (socket) {
      // Log all events received by the socket
      socket.onAny((event, ...args) => {
        console.log(`Socket event received: ${event}`, args);
      });

      // Cleanup the listener on unmount
      return () => {
        socket.offAny();
      };
    }
  }, [socket]);

  useEffect(() => {
    if (auth.isAuthenticated && auth.user?.sub && socket) {
      socket.on("systems", (data: System[]) => {
        setSystems(data); // Update global systems state
      });

      socket.on("system_online", (system: System) => {
        console.log("SYSTEM_ONLINE");
        setSystems((prevSystems) => {
          const updatedSystems = prevSystems.map((s) =>
            s.id === system.id ? system : s
          );
          // Trigger the toast notification
          toast.success(`${system.system_name} is now online!`, {
            style: { backgroundColor: "rgb(13, 110, 253)", color: "white" },
            hideProgressBar: true,
          });
          // Check if the system was not found in the previous state, add it if necessary
          const systemExists = prevSystems.some((s) => s.id === system.id);
          if (systemExists) {
            return prevSystems.map((s) => (s.id === system.id ? system : s));
          }
          // Add the new system to the list
          return [...prevSystems, system];
        });
      });

      socket.on("system_offline", (system: System) => {
        console.log("SYSTEM_OFFLINE");
        setSystems((prevSystems) => {
          console.log(prevSystems, system);
          return prevSystems.map((s) => (s.id === system.id ? system : s));
        });
        // Trigger the toast notification
        toast.error(`${system.system_name} is now offline!`, {
          style: { backgroundColor: "rgba(220,53,69,1)", color: "white" },
          hideProgressBar: true,
        });
      });

      socket.on("system_change", (system: System) => {
        setSystems((prevSystems) =>
          prevSystems.map((s) => (s.id === system.id ? { ...s, ...system } : s))
        );
      });

      return () => {
        socket.off("systems");
        socket.off("system_online");
        socket.off("system_offline");
        socket.off("system_change");
      };
    }
  }, [auth.isAuthenticated, auth.user?.sub, socket]);

  return (
    <SystemsContext.Provider value={{ systems, setSystems }}>
      {children}
    </SystemsContext.Provider>
  );
};

// Custom hook to use the systems context
export const useSystems = () => {
  const context = useContext(SystemsContext);
  if (!context) {
    throw new Error("useSystems must be used within a SystemsProvider");
  }
  return context;
};
