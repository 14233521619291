// src/components/SocketContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { useAuth } from "./AuthContext";

interface SocketContextType {
  socket: Socket | null;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated, user } = useAuth(); // Access the auth context to check authentication
  const [socket, setSocket] = useState<Socket | null>(null);
  useEffect(() => {
    if (isAuthenticated && user) {
      const newSocket = io({
        auth: { userId: user.sub },
        reconnection: true,
        withCredentials: true,
        transports: ["polling", "websocket"],
      });
      setSocket(newSocket);

      let heartbeatInterval: NodeJS.Timeout;

      newSocket.on("connect", () => {
        console.log("Socket connected");
        heartbeatInterval = setInterval(() => {
          if (newSocket) {
            newSocket.emit("p");
          }
        }, 2000); // Every 2 seconds

        newSocket.emit("login", user.sub);
      });

      newSocket.on("q", () => {
        console.log("Heartbeat");
      });

      return () => {
        clearInterval(heartbeatInterval); // Clear interval on cleanup
        newSocket.disconnect();
        setSocket(null); // Clean up the socket connection
      };
    }
  }, [isAuthenticated, user]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};
